import { dispatchShowAlertChanges } from "../../modules/util/events";

angular.module('meanApp')
  .controller('suitability-controller', function ($scope, $rootScope, $state, $stateParams, $filter, DataM, $window, Auth, empowerDataService, $timeout, $location, Idle, DataHelper, $document) {
    $scope.savedText = "Save";
    $scope.showSpinner = true;
    $scope.showRequiredField = false;
    $scope.requiredDefLoanReason = true;
    $scope.showReferenceIdForm = false;
    $scope.showAddForm = false;
    $scope.newIdTaken = false;
    $scope.showTNC = false;
    $scope.showErrorMessage = false;
    $scope.showOtherLending = false;
    $scope.errorTitle = "";
    $scope.errorMessage = "";
    $scope.filter = {
      referenceId: "all"
    };
    $scope.userInfo = {

    }
    $scope.referenceIdList = [];
    $scope.newSelectedLending = {
      id: null
    };
    // only numbers pattern
    $scope.onlyNumbers = /^[0-9]/;

    $scope.newForm = {
      suitability: {},
      referenceId: "Lending Needs " + new Date().toLocaleDateString("en-AU")
    };
    // lock
    $scope.lockText = "Lock";
    $scope.superAdmin = false;
    // suitability 
    $scope.lendingAmountInfo = {
      otherComment: "",
      specificAmount: null,
      bestDescription: ""
    };
    $scope.loanTermInfo = {
      otherComment: "",
      particularTerm: null,
      bestDescription: ""
    };

    $scope.showSuitabilityList = false;
    $scope.timestamp = "";

    $scope.selectedSuitability = {
      id: null,
      referenceId: null,
      locked: false,
      newRefId: null
    };

    if ($state.params) {
      if ($state.params.suitabilityId) {
        $scope.userInfo.id = $state.params.clientId;
        $scope.selectedSuitability.id = $state.params.suitabilityId;
      }
    }

    $scope.suitabilityList = [];
    $scope.changesWillAffectFinancialCommitments = false;
    $scope.changesWillAffectFinancialCommitmentsDesc = "";

    $scope.defaultLoanReasonsDescription = ['Buy property to live in', 'Refinance', 'Buy property for investment', 'Consolidate debt', 'Build dwelling to live in', 'Access equity for personal use', 'Build dwelling as investment', 'Access equity for investment use'];

    $scope.defaultLoanReasons = [];
    for (var i = 0; i < $scope.defaultLoanReasonsDescription.length; i++) {
      $scope.defaultLoanReasons.push({
        description: $scope.defaultLoanReasonsDescription[i],
        isChecked: false
      });
    }

    $scope.defaultCharFeaturesLoanDescription = ['Variable interest rate', 'Fixed interest rate', 'Principal & Interest repayments', 'Interest-only repayments', 'Offset account', 'Ability to make additional payments', 'Line of Credit', 'Construction loan', 'Branch access', 'Electronic banking', 'Redraw'];
    $scope.defaultCharFeaturesLoan = [];
    for (var i = 0; i < $scope.defaultCharFeaturesLoanDescription.length; i++) {
      $scope.defaultCharFeaturesLoan.push({
        description: $scope.defaultCharFeaturesLoanDescription[i],
        selectedOption: "",
        comment: ""
      });
    }
    $scope.additionalReasons = [];
    $scope.charFeaturesLoan = [];
    $scope.additionalFeatures = [];
    $scope.otherReason = {};
    $scope.featureOther = "";
    $scope.loanReasons = {
      reasons: $scope.defaultLoanReasons,
      additionalReasons: $scope.additionalReasons,
      other: $scope.otherReason
    };
    $scope.requiredFeaturesOfLoanInfo = {
      additionalFeatures: $scope.additionalFeatures,
      features: $scope.defaultCharFeaturesLoan,
      otherDesc: $scope.featureOther
    };

    $scope.showBottomButtons = false;
    let toCompare = null
    $scope.tempInitial = false
    $scope.initialSuitability = null
    $scope.showChangesAlert = false;
    $scope.toStateName = ""
    $scope.isSaving = false;

    $scope.closeChangesAlert = function () {
      $scope.newLocation = "";
      $scope.showChangesAlert = false;
      dispatchShowAlertChanges($scope.showChangesAlert);
      $scope.isSaving = false;
    }

    $scope.redirectSave = function () {
      const location = "suitability"
      $scope.saveSuitabilityRedirect(location)
    }

    $scope.dashboardSave = function () {
      var location = "suitability";
      if ($scope.isSaving && $scope.toStateName !== "") {
        location = $scope.toStateName
      }
      $scope.saveSuitability(location)
      $scope.tempInitial = false
    };

    $scope.redirectSave = function () {
      const location = "suitability"
      $scope.saveSuitabilityRedirect(location)
    }
    				
    $scope.dashboardDiscard = async function () {			
      $scope.showSpinner = true;			
      $scope.loadingMessage = "Discarding Client Changes...";			
      if($scope.showChangesAlert) {			
        $scope.showChangesAlert = false;	
        dispatchShowAlertChanges($scope.showChangesAlert);		
      }			
    				
      var promise = new Promise(function(resolve, reject) {			
        $timeout(function() {			
          $scope.setInputValues(true)			
          resolve("done");			
        }, 1)			
      });			
    				
      let result = await promise;			
      if(result === "done") {			
          $scope.showSpinner = false;			
          if($scope.isSaving && $scope.toStateName !== "") {			
            $state.go($scope.toStateName, {			
              clientId: $state.params.clientId			
            });			
          } else {			
              $scope.showBottomButtons = false
              $rootScope.setAlertValues(true, true, "Successfully discarded changes!", true)			
    	      }        			
          }
    }
    // setup 
    // check if user is admin
    DataM.getMe().then(function (userData) {

      if (userData.role !== 'user' && userData.role !== 'guest') {
        $scope.isAdmin = true;

      } else {
        // redirect if user
        $scope.isAdmin = false;
      }
      if (userData.role === "super_admin") {
        $scope.superAdmin = true;
      }

    }).catch(function (err) {
      console.log('Retrieve current user error');
    });

    $scope.addAdditionalReason = function () {
      var reason = {
        isChecked: true,
        description: ""
      }
      $scope.additionalReasons.push(reason);
      if (toCompare.suitability.loanReasons.additionalReasons.length === 0) {
        toCompare.suitability.loanReasons.additionalReasons.push(reason);
      }
    }

    $scope.removeAdditionalReason = function (reason) {
      $scope.additionalReasons.splice($scope.additionalReasons.indexOf(reason), 1);
      if (toCompare.suitability.loanReasons.additionalReasons.indexOf(reason) !== -1) {
        toCompare.suitability.loanReasons.additionalReasons
          .splice(toCompare.suitability.loanReasons.additionalReasons.indexOf(reason), 1);
      }
    }

    $scope.isInvalidForFlex = function (object) {
      return DataHelper.isObjectInvalid(object) && $scope.selectedSuitability.locked != true;
    };

    $scope.addAdditionalRequirement = function () {
      var requirement = {
        description: "",
        selectedOption: "",
        comment: ""
      };
      $scope.additionalFeatures.push(requirement);
      if (toCompare.suitability.requiredFeaturesOfLoanInfo.additionalFeatures.length === 0) {
        toCompare.suitability.requiredFeaturesOfLoanInfo.additionalFeatures.push(requirement);
      }
    }

    $scope.removeAdditionalRequirement = function (requirement) {
      $scope.additionalFeatures.splice($scope.additionalFeatures.indexOf(requirement), 1);
      if (toCompare.suitability.requiredFeaturesOfLoanInfo.additionalFeatures.indexOf(requirement) !== -1) {
        toCompare.suitability.requiredFeaturesOfLoanInfo.additionalFeatures
          .splice(toCompare.suitability.requiredFeaturesOfLoanInfo.additionalFeatures.indexOf(requirement), 1);
      }
    }


    function getSuitabilityReferenceIdList() {
      $scope.showSpinner = true;
      DataM.getSuitabilityReferenceIdList().then(function (data) {
        $scope.showSpinner = false;
        $scope.referenceIdList = data;
        $scope.checkTakenRefId($scope.newForm.referenceId);
      }).catch(function (err) {
        console.log('Retrieve Suitability error', err);
      });
    }

    $scope.checkTakenRefId = function (newRefId) {
      $scope.newIdTaken = false;
      for (var i = 0; i < $scope.referenceIdList.length; i++) {

        var refId = $scope.referenceIdList[i].referenceId;

        if (typeof refId === "string" && newRefId === refId) {
          $scope.newIdTaken = true;
          break;
        }
      }

    }

    function getSuitabilityList() {
      DataM.getSuitabilityList({
        referenceId: $scope.filter.referenceId
      }).then(function (data) {

        $scope.showSpinner = false;
        $scope.suitabilityList = data;
      }).catch(function (err) {
        console.log('Retrieve Suitability error', err);
      });
    }



    $scope.checkIfOneDefaultLoanReasonChecked = function () {
      var didNotCheck1 = true;
      for (var i = 0; i < $scope.defaultLoanReasons.length; i++) {
        if ($scope.defaultLoanReasons[i].isChecked === true) {
          didNotCheck1 = false;
        } else if (typeof $scope.defaultLoanReasons[i].isChecked === 'undefined') {
          $scope.defaultLoanReasons[i].isChecked = false;
        }

        let selected = toCompare.suitability.loanReasons.reasons[i]

        if (selected === undefined)
          toCompare.suitability.loanReasons.reasons = $scope.defaultLoanReasons
        else toCompare.suitability.loanReasons.reasons[i].isChecked = $scope.defaultLoanReasons[i].isChecked

      }
      if ($scope.otherReason.isChecked == true) {
        didNotCheck1 = false;
      }
      $scope.requiredDefLoanReason = didNotCheck1;

    }

    $scope.selectFeatureLoan = function (index, defaultCharFeatureLoan) {
      let selected = toCompare.suitability.requiredFeaturesOfLoanInfo.features[index]
      if (selected === undefined) {
        toCompare.suitability.requiredFeaturesOfLoanInfo.features = defaultCharFeatureLoan
      } else {
        toCompare.suitability.requiredFeaturesOfLoanInfo.features[index].selectedOption = defaultCharFeatureLoan.selectedOption
      }
    }

    $scope.selectAddFeatureLoan = function (index, additionalFeature) {
      let selected = toCompare.suitability.requiredFeaturesOfLoanInfo.additionalFeatures[index]
      if (selected === undefined) {
        toCompare.suitability.requiredFeaturesOfLoanInfo.additionalFeatures = additionalFeature
      } else {
        toCompare.suitability.requiredFeaturesOfLoanInfo.additionalFeatures[index].selectedOption = additionalFeature.selectedOption
      }
    }

    $scope.onChangeFeatureComment = function (index, comment) {
      if (toCompare.suitability.requiredFeaturesOfLoanInfo.features.some(
        feature => feature.description === comment.description)) {
        toCompare.suitability.requiredFeaturesOfLoanInfo.features[index].comment = comment.comment
      } else {
        toCompare.suitability.requiredFeaturesOfLoanInfo.features.push(comment)
      }
    }

    $scope.onChangeFeatureOther = function (value) {
      toCompare.suitability.requiredFeaturesOfLoanInfo.otherDesc = value
    }

    $scope.onChangesWillAffectFinancialCommitments = function (value) {
      toCompare.suitability.changesWillAffectFinancialCommitments = value
    }

    $scope.onChangesWillAffectFinancialCommitmentsDesc = function (value) {
      toCompare.suitability.changesWillAffectFinancialCommitmentsDesc = value
    }

    $scope.saveSuitabilityRedirect = function (location) {
      $scope.checkIfOneDefaultLoanReasonChecked();
      console.log("THIS IS THE FUNCTION 2")
      if ($scope.requiredDefLoanReason !== true) {
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
          dispatchShowAlertChanges($scope.showChangesAlert);
        }
      }

      if (!$scope.form.$valid) {
        $scope.showRequiredField = true;
        angular.element($document[0].querySelector('input.ng-invalid'))[0].focus();
        return false;
      }
      $scope.showSpinner = true;
      $scope.loanReasons = {
        reasons: $scope.defaultLoanReasons,
        additionalReasons: $scope.additionalReasons,
        other: $scope.otherReason
      };
      $scope.requiredFeaturesOfLoanInfo = {
        additionalFeatures: $scope.additionalFeatures,
        features: $scope.defaultCharFeaturesLoan,
        otherDesc: $scope.featureOther
      };

      var formData = {
        suitability: {
          lendingAmountInfo: $scope.lendingAmountInfo,
          loanTermInfo: $scope.loanTermInfo,
          loanReasons: $scope.loanReasons,
          requiredFeaturesOfLoanInfo: $scope.requiredFeaturesOfLoanInfo,
          changesWillAffectFinancialCommitments: $scope.changesWillAffectFinancialCommitments,
          changesWillAffectFinancialCommitmentsDesc: $scope.changesWillAffectFinancialCommitmentsDesc
        }
      }
      if (typeof $scope.selectedSuitability.referenceId === "string") {
        formData.referenceId = $scope.selectedSuitability.referenceId;
      }
      submitSuitabilityRedirect(formData, location);
    }

    function submitSuitabilityRedirect(formData, location) {
      DataM.saveSuitability(formData).then(function (data) {
        //$scope.showSpinner = false;
        $rootScope.setAlertValues(true, true, "Successfully saved changes!", true)
        $scope.initialSuitability = null
        $scope.initialSuitability = JSON.stringify(toCompare, function (key, value) {
          if (key === "$$hashKey") {
            return undefined;
          }
          if (key === "$promise") {
            return undefined;
          }
          if (key === "$resolved") {
            return undefined;
          }
          return value;
        });
        $scope.selectedSuitability.id = data._id;
        $state.go($scope.toStateName, {
          clientId: data.uid
        });
        // after save always get latest version of suitability
      })
        .catch(function (err) {
          console.log('test err', err)
          $rootScope.setAlertValues(true, false, "There is a problem accessing the server.", true)
          alert("There is a problem accessing the server. Please make sure your internet connection is working. If it is working and this problem persist, please report this problem to Empower Wealth team.");
          $scope.showSpinner = false;
        });
    }
    //save suitability
    $scope.saveSuitability = function (location) {
      $scope.checkIfOneDefaultLoanReasonChecked();
      if ($scope.requiredDefLoanReason !== true) {
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
          dispatchShowAlertChanges($scope.showChangesAlert);
        }
      }

      if (!$scope.form.$valid) {
        $scope.showRequiredField = true;
        angular.element($document[0].querySelector('input.ng-invalid'))[0].focus();
        return false;
      }
      $scope.showSpinner = true;
      $scope.loanReasons = {
        reasons: $scope.defaultLoanReasons,
        additionalReasons: $scope.additionalReasons,
        other: $scope.otherReason
      };
      $scope.requiredFeaturesOfLoanInfo = {
        additionalFeatures: $scope.additionalFeatures,
        features: $scope.defaultCharFeaturesLoan,
        otherDesc: $scope.featureOther
      };

      var formData = {
        suitability: {
          lendingAmountInfo: $scope.lendingAmountInfo,
          loanTermInfo: $scope.loanTermInfo,
          loanReasons: $scope.loanReasons,
          requiredFeaturesOfLoanInfo: $scope.requiredFeaturesOfLoanInfo,
          changesWillAffectFinancialCommitments: $scope.changesWillAffectFinancialCommitments,
          changesWillAffectFinancialCommitmentsDesc: $scope.changesWillAffectFinancialCommitmentsDesc
        }
      }
      if (typeof $scope.selectedSuitability.referenceId === "string") {
        formData.referenceId = $scope.selectedSuitability.referenceId;
      }
      submitSuitability(formData, location);
    }

    function submitSuitability(formData, location) {
      DataM.saveSuitability(formData).then(function (data) {
        //$scope.showSpinner = false;
        $scope.selectedSuitability.id = data._id;

        // after save always get latest version of suitability
        Auth.isAdmin().then(isAdmin => {
          if (!isAdmin) {
            $state.go('lockedsuitability', {
              suitabilityId: $scope.selectedSuitability.id
            });
          } else {
            $state.go('clientsuitability', {
              clientId: data.uid,
              suitabilityId: $scope.selectedSuitability.id
            });
          }
        });
        if ($location.$$url.includes("suitability")) {
          if ($scope.isSaving && $scope.toStateName !== "") {
            $state.go($scope.toStateName, {
              clientId: data.uid
            });
          } else {
            $rootScope.setAlertValues(true, true, "Successfully saved changes!", true)
            $scope.initialSuitability = null
            $scope.initialSuitability = JSON.stringify(toCompare, function (key, value) {
              if (key === "$$hashKey") {
                return undefined;
              }
              if (key === "$promise") {
                return undefined;
              }
              if (key === "$resolved") {
                return undefined;
              }
              return value;
            });
          }
        } else {
          $state.go(location, {
            clientId: data.uid
          });
        }
      })
        .catch(function (err) {
          console.log('test err', err)
          $rootScope.setAlertValues(true, false, "There is a problem accessing the server.", true)
          alert("There is a problem accessing the server. Please make sure your internet connection is working. If it is working and this problem persist, please report this problem to Empower Wealth team.");
          $scope.showSpinner = false;
        });
    }

    
    $scope.saveSuitabilityRedirect = function (location) {
      $scope.checkIfOneDefaultLoanReasonChecked();
      if ($scope.requiredDefLoanReason !== true) {
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
          dispatchShowAlertChanges($scope.showChangesAlert);
        }
      }

      if (!$scope.form.$valid) {
        $scope.showRequiredField = true;
        angular.element($document[0].querySelector('input.ng-invalid'))[0].focus();
        return false;
      }
      $scope.showSpinner = true;
      $scope.loanReasons = {
        reasons: $scope.defaultLoanReasons,
        additionalReasons: $scope.additionalReasons,
        other: $scope.otherReason
      };
      $scope.requiredFeaturesOfLoanInfo = {
        additionalFeatures: $scope.additionalFeatures,
        features: $scope.defaultCharFeaturesLoan,
        otherDesc: $scope.featureOther
      };

      var formData = {
        suitability: {
          lendingAmountInfo: $scope.lendingAmountInfo,
          loanTermInfo: $scope.loanTermInfo,
          loanReasons: $scope.loanReasons,
          requiredFeaturesOfLoanInfo: $scope.requiredFeaturesOfLoanInfo,
          changesWillAffectFinancialCommitments: $scope.changesWillAffectFinancialCommitments,
          changesWillAffectFinancialCommitmentsDesc: $scope.changesWillAffectFinancialCommitmentsDesc
        }
      }
      if (typeof $scope.selectedSuitability.referenceId === "string") {
        formData.referenceId = $scope.selectedSuitability.referenceId;
      }
      submitSuitabilityRedirect(formData, location);
    }

    function submitSuitabilityRedirect(formData, location) {
      DataM.saveSuitability(formData).then(function (data) {
        //$scope.showSpinner = false;
        $rootScope.setAlertValues(true, true, "Successfully saved changes!", true)
        $scope.initialSuitability = null
        $scope.initialSuitability = JSON.stringify(toCompare, function (key, value) {
          if (key === "$$hashKey") {
            return undefined;
          }
          if (key === "$promise") {
            return undefined;
          }
          if (key === "$resolved") {
            return undefined;
          }
          return value;
        });
        $scope.selectedSuitability.id = data._id;
        $state.go($scope.toStateName, {
          clientId: data.uid
        });
        // after save always get latest version of suitability
      })
        .catch(function (err) {
          console.log('test err', err)
          $rootScope.setAlertValues(true, false, "There is a problem accessing the server.", true)
          alert("There is a problem accessing the server. Please make sure your internet connection is working. If it is working and this problem persist, please report this problem to Empower Wealth team.");
          $scope.showSpinner = false;
        });
    }

    function formatDefaultLoanReason(loanReasons, isInitial) {
      if (isInitial === true) {
        $scope.defaultLoanReasons = [];
        for (var i = 0; i < $scope.defaultLoanReasonsDescription.length; i++) {
          $scope.defaultLoanReasons.push({
            description: $scope.defaultLoanReasonsDescription[i],
            isChecked: false
          });
        }
      } else {
        for (var i = 0; i < loanReasons.length; i++) {
          for (var j = 0; j < $scope.defaultLoanReasons.length; j++) {
            if (loanReasons[i].description && loanReasons[i].description == $scope.defaultLoanReasons[j].description) {
              $scope.defaultLoanReasons[j].isChecked = loanReasons[i].isChecked;
            }
          }
        }
      }
    }

    function formatDefaultFeatures(features, isInitial) {
      if (isInitial === true) {
        $scope.defaultCharFeaturesLoan = [];
        for (var i = 0; i < $scope.defaultCharFeaturesLoanDescription.length; i++) {
          $scope.defaultCharFeaturesLoan.push({
            description: $scope.defaultCharFeaturesLoanDescription[i],
            selectedOption: "",
            comment: ""
          });
        }
      } else {
        for (var i = 0; i < features.length; i++) {
          for (var j = 0; j < $scope.defaultCharFeaturesLoan.length; j++) {
            if (features[i].description && features[i].description == $scope.defaultCharFeaturesLoan[j].description) {
              $scope.defaultCharFeaturesLoan[j].selectedOption = features[i].selectedOption;
              $scope.defaultCharFeaturesLoan[j].comment = features[i].comment;
            }
          }
        }
      }
    }

    $scope.assignValues = function (data) {
      if (data.timestamp) {
        $scope.timestamp = data.timestamp;
      }
      if (data.suitability) {
        if (data.suitability.lendingAmountInfo) {
          $scope.lendingAmountInfo = data.suitability.lendingAmountInfo;
        }

        if (data.suitability.loanTermInfo) {
          $scope.loanTermInfo = data.suitability.loanTermInfo;
        }

        if (data.suitability.loanReasons) {
          if (data.suitability.loanReasons.reasons.length > 0) {
            formatDefaultLoanReason(data.suitability.loanReasons.reasons, false);
          } else {
            formatDefaultLoanReason(null, true);
          }
          if (data.suitability.loanReasons.additionalReasons.length > 0) {
            $scope.additionalReasons = data.suitability.loanReasons.additionalReasons;
          } else $scope.additionalReasons = []

          $scope.otherReason = data.suitability.loanReasons.other;
        }

        if (data.suitability.requiredFeaturesOfLoanInfo) {
          if (data.suitability.requiredFeaturesOfLoanInfo.features.length > 0) {
            formatDefaultFeatures(data.suitability.requiredFeaturesOfLoanInfo.features, false);
          } else {
            formatDefaultFeatures(null, true);
          }
          if (data.suitability.requiredFeaturesOfLoanInfo.additionalFeatures.length > 0) {
            $scope.additionalFeatures = data.suitability.requiredFeaturesOfLoanInfo.additionalFeatures;
          } else $scope.additionalFeatures = []
          $scope.featureOther = data.suitability.requiredFeaturesOfLoanInfo.otherDesc;
        }
        $scope.changesWillAffectFinancialCommitments = data.suitability.changesWillAffectFinancialCommitments;

        if (data.suitability.changesWillAffectFinancialCommitments !== true) {
          $scope.changesWillAffectFinancialCommitments = false;
        }
        $scope.changesWillAffectFinancialCommitmentsDesc = data.suitability.changesWillAffectFinancialCommitmentsDesc;
      }
      $scope.checkIfOneDefaultLoanReasonChecked();
    }

    $scope.setInputValues = function (isDiscard) {
      $scope.lendingAmountInfo = {
        specificAmount: "",
        otherComment: ""
      }
      $scope.loanTermInfo = {
        otherComment: "",
        particularTerm: ""
      }
      $scope.loanReasons = {
        reasons: {},
        additionalReasons: [],
        other: {
          description: "",
          isChecked: false
        }
      };

      $scope.requiredFeaturesOfLoanInfo = {
        additionalFeatures: [],
        features: [],
        otherDesc: ""
      };

      $scope.changesWillAffectFinancialCommitments = false;
      $scope.changesWillAffectFinancialCommitmentsDesc = "";

      if (!isDiscard) {
        DataM.getSuitability({
          suitabilityId: $scope.selectedSuitability.id
        }).then(function (data) {
          if (data.suitability === undefined || data.suitability.lendingAmountInfo === undefined) {

            let reasonsLoan = []
            for (var i = 0; i < $scope.defaultLoanReasonsDescription.length; i++) {
              reasonsLoan.push({
                description: $scope.defaultLoanReasonsDescription[i],
                isChecked: false
              });
            }

            let featuresLoan = []
            for (var i = 0; i < $scope.defaultCharFeaturesLoanDescription.length; i++) {
              featuresLoan.push({
                description: $scope.defaultCharFeaturesLoanDescription[i],
                selectedOption: "",
                comment: ""
              });
            }

            data.suitability = {
              lendingAmountInfo: {
                specificAmount: "",
                otherComment: ""
              },
              loanTermInfo: {
                otherComment: "",
                particularTerm: ""
              },
              loanReasons: {
                reasons: reasonsLoan,
                additionalReasons: [],
                other: {
                  description: "",
                  isChecked: false
                }
              },
              requiredFeaturesOfLoanInfo: {
                additionalFeatures: [],
                features: featuresLoan,
                otherDesc: ""
              },
              changesWillAffectFinancialCommitments: false,
              changesWillAffectFinancialCommitmentsDesc: ""
            }
            $scope.initialSuitability = JSON.stringify(data)
            $scope.tempInitial = true
          }

          toCompare = data

          var promise = new Promise(function (resolve, reject) {
            $scope.assignValues(data)
            resolve("done");
          });


          promise.then(function (data) {
            if (data === "done") {
              $scope.showSpinner = false;
            }
          });

          if (data.referenceId) {
            $scope.selectedSuitability.referenceId = data.referenceId;
            $scope.newSelectedLending.id = $scope.selectedSuitability.referenceId;
          }
          if (!data.referenceId) {
            $scope.selectedSuitability.referenceId = null;
          }
          if (typeof data.locked === 'boolean') {
            $scope.selectedSuitability.locked = data.locked;
          } else {
            $scope.selectedSuitability.locked = false;
          }

          $scope.lockText = data.locked ? "Unlock" : 'Lock';
          if (data._id) {
            $scope.selectedSuitability.id = data._id;
          }
        }).catch(function (err) {
          $scope.showSuitabilityList = false;
          console.log('Retrieve Suitability error', err);
        });
      } else {
        var data = JSON.parse($scope.initialSuitability);
        if ($scope.tempInitial === true) {
          let reasonsLoan = []
          for (var i = 0; i < $scope.defaultLoanReasonsDescription.length; i++) {
            reasonsLoan.push({
              description: $scope.defaultLoanReasonsDescription[i],
              isChecked: false
            });
          }

          let featuresLoan = []
          for (var i = 0; i < $scope.defaultCharFeaturesLoanDescription.length; i++) {
            featuresLoan.push({
              description: $scope.defaultCharFeaturesLoanDescription[i],
              selectedOption: "",
              comment: ""
            });
          }

          data.suitability = {
            lendingAmountInfo: {
              specificAmount: "",
              otherComment: ""
            },
            loanTermInfo: {
              otherComment: "",
              particularTerm: ""
            },
            loanReasons: {
              reasons: reasonsLoan,
              additionalReasons: [],
              other: {
                description: "",
                isChecked: false
              }
            },
            requiredFeaturesOfLoanInfo: {
              additionalFeatures: [],
              features: featuresLoan,
              otherDesc: ""
            },
            changesWillAffectFinancialCommitments: false,
            changesWillAffectFinancialCommitmentsDesc: ""
          }
        }

        toCompare = data
        $scope.assignValues(data);
      }
    }

    $scope.setInputValues(false);


    $scope.changeBottomButtonState = function () {
      if ($scope.initialSuitability !== null && toCompare !== null) {
        var newToCompare = JSON.stringify(toCompare, function (key, value) {
          if (key === "$$hashKey") {
            return undefined;
          }
          if (key === "$promise") {
            return undefined;
          }
          if (key === "$resolved") {
            return undefined;
          }
          return value;
        });
        if ($scope.initialSuitability !== newToCompare) {
          $scope.showBottomButtons = true
        } else {
          $scope.showBottomButtons = false
        }
      } else if ($scope.initialSuitability === null && toCompare !== null) {
        $scope.initialSuitability = JSON.stringify(toCompare, function (key, value) {
          if (key === "$$hashKey") {
            return undefined;
          }
          if (key === "$promise") {
            return undefined;
          }
          if (key === "$resolved") {
            return undefined;
          }
          return value;
        });
      }
    }

    $scope.$watch("[lendingAmountInfo, loanTermInfo, defaultLoanReasons, otherReason, additionalReasons, defaultCharFeaturesLoan, requiredFeaturesOfLoanInfo, featureOther, additionalFeatures, changesWillAffectFinancialCommitments, changesWillAffectFinancialCommitmentsDesc]", function () {
      $scope.changeBottomButtonState()
    }, true);

    $scope.suitabilityButtonPressed = function () {
      $scope.showSuitabilityList = true;
      getSuitabilityList();

      getSuitabilityReferenceIdList();
    }
    $scope.addFormPressed = function () {
      $scope.showAddForm = true;
    }
    $scope.closeSuitabilityButtonPressed = function () {
      $scope.showSuitabilityList = false;
      $scope.showReferenceIdForm = false;
      $scope.showTNC = false;
      $scope.showAddForm = false;
      $scope.showErrorMessage = false;
      $scope.showOtherLending = false;
      $scope.errorTitle = "";
      $scope.errorMessage = "";
      if (!$scope.form.$valid && $scope.showRequiredField === true) {
        angular.element($document[0].querySelector('input.ng-invalid'))[0].focus();
      }
      $scope.showRequiredField = false;
    }

    $scope.updateReferenceIdPressed = function () {
      $scope.showReferenceIdForm = true;
    }

    $scope.approveForm = function () {
      $scope.showTNC = true;
    }
    $scope.showOtherLendingNeeds = function () {
      $scope.showOtherLending = true;
    }
    $scope.pressLock = function () {

      if ($scope.superAdmin === true) {
        $scope.selectedSuitability.locked = !$scope.selectedSuitability.locked;
      } else {
        $scope.selectedSuitability.locked = true;
      }

      $scope.lockSuitability();
    }

    $scope.saveNewForm = function () {

      $scope.showSpinner = true;

      DataM.saveSuitability($scope.newForm).then(function (data) {
        $scope.showSpinner = false;
        $scope.showAddForm = false;
        $scope.selectedSuitability.id = data._id;
        //$rootScope.$broadcast('onSuitabilityLocked');
        console.log("THIS IS THE FUNCTION 1")
        $state.go('clientsuitability', {
          clientId: data.uid,
          suitabilityId: $scope.selectedSuitability.id
        });
      })
        .catch(function (err) {
          $scope.showSpinner = false;
          console.log('Save Suitability error', err);
        });
    }

    $scope.acceptApproveForm = function () {

      $scope.checkIfOneDefaultLoanReasonChecked();

      if (!$scope.form.$valid) {
        $scope.showTNC = false;
        $scope.showRequiredField = true;
        angular.element($document[0].querySelector('input.ng-invalid'))[0].focus();
        return false;
      }
      $scope.showSpinner = true;
      $scope.loanReasons = {
        reasons: $scope.defaultLoanReasons,
        additionalReasons: $scope.additionalReasons,
        other: $scope.otherReason
      };
      $scope.requiredFeaturesOfLoanInfo = {
        additionalFeatures: $scope.additionalFeatures,
        features: $scope.defaultCharFeaturesLoan,
        otherDesc: $scope.featureOther
      };

      var formData = {
        suitability: {
          lendingAmountInfo: $scope.lendingAmountInfo,
          loanTermInfo: $scope.loanTermInfo,
          loanReasons: $scope.loanReasons,
          requiredFeaturesOfLoanInfo: $scope.requiredFeaturesOfLoanInfo,
          changesWillAffectFinancialCommitments: $scope.changesWillAffectFinancialCommitments,
          changesWillAffectFinancialCommitmentsDesc: $scope.changesWillAffectFinancialCommitmentsDesc
        },
        locked: true
      }
      if (typeof $scope.selectedSuitability.referenceId === "string") {
        formData.referenceId = $scope.selectedSuitability.referenceId;
      }
      $scope.showTNC = false;
      submitSuitability(formData);
    }
    $scope.saveReferenceId = function () {

      $scope.showSpinner = true;

      var formData = {
        oldReferenceId: $scope.selectedSuitability.referenceId,
        newReferenceId: $scope.selectedSuitability.newRefId
      }

      DataM.updateReferenceId(formData).then(function (data) {
        $scope.showSpinner = false;
        $scope.showReferenceIdForm = false;
        $scope.selectedSuitability.referenceId = $scope.selectedSuitability.newRefId;
        $rootScope.$broadcast('onSuitabilityLocked');
      })
        .catch(function (err) {
          $scope.showSpinner = false;
          $scope.showReferenceIdForm = false;
          $scope.showErrorMessage = true;
          $scope.errorTitle = "Reference ID Update";
          $scope.errorMessage = "Reference ID Already taken or Invalid."
          console.log('Save Suitability error', err);
        });
    }

    $scope.lockSuitability = function () {

      if ($scope.selectedSuitability.locked) {
        $scope.lockText = "Unlock";
      } else {
        $scope.lockText = "Lock";
      }

      DataM.lockSuitability({
        locked: $scope.selectedSuitability.locked,
        suitabilityId: $scope.selectedSuitability.id,
        referenceId: $scope.selectedSuitability.referenceId
      }).then(function (data) {
        $scope.setInputValues(false);
        $scope.showSpinner = false;
        $rootScope.$broadcast('onSuitabilityLocked');
      })
        .catch(function (err) {
          $scope.showSpinner = false;
          console.log('Save Suitability error', err);
        });
    }

    getSuitabilityReferenceIdList();

    $scope.numbersOnly = function (text, modelToChange) {
      var transformedText = '';
      if (text) {
        transformedText = text.replace(/[^0-9]/g, '');
      }
      if (modelToChange == 'lendingAmountInfo.specificAmount') {
        $scope.lendingAmountInfo.specificAmount = transformedText;
      }
      if (modelToChange == 'loanTermInfo.particularTerm') {
        $scope.loanTermInfo.particularTerm = transformedText;
      }
    }

    $scope.printLendingNeeds = function () {
      $timeout($window.print, 0);
    }

    $scope.changeSelectedLendingNeeds = function () {

      // State go
      $state.go('clientsuitability', {
        clientId: $stateParams.clientId,
        suitabilityId: $scope.newSelectedLending.id
      });
    }

    $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

      if (toState.url.indexOf("suitability") == -1 && !$scope.isSaving && !$scope.didNextSave) {
        $scope.isSaving = true;
        $scope.toStateName = toState.name;
        event.preventDefault();
        if ($scope.showBottomButtons) {
          $scope.showChangesAlert = true;
          dispatchShowAlertChanges($scope.showChangesAlert);
        } else {
          $state.go(toState.name, {
            clientId: $stateParams.clientId
          });
          return;
        }
      }
    });
  });
