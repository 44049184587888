// References to external repositories:
// LINK field-validation-modal/src/pages/public/accounts/AccountsPages.tsx

const allForwardSlashRegex = /\//g;

/**
 * NOTE List down all needed paths here for React. This avoids automatic
 *      replacement of `/` pathname into `~2F`.
 */
const paths = [
  "/invited",
  "/new",
  "/new/components",
  "/new/bills/:clientId",
  "/new/spending/:clientId",
  "/new/income/:clientId",
  "/public/accounts/terms",
  "/public/accounts/reset-password/repeat-request",
  "/public/accounts/reset-password/success",
  "/public/accounts/reset-password/expired",
  "/public/accounts/reset-password/change",
  "/public/accounts/reset-password",
  "/public/accounts/security-setup",
  "/public/accounts/confirm-security",
  "/public/accounts/otp",
  "/public/accounts/sms",
];

const pathnameAndUrlPatternPairs = [
  {
    pathname: "/public/accounts/sign-up",
    urlPattern: "/public/accounts/sign-up?name&email&user-type&messaging-id&invited"
  },
  {
    pathname: "/public/accounts",
    urlPattern: "/public/accounts?email"
  },
];

angular.module("meanApp").config(($stateProvider) => {
  pathnameAndUrlPatternPairs.forEach(({ pathname, urlPattern }) => {
    $stateProvider.state(pathname, {
      url: urlPattern,
    });
  })

  paths.forEach((path) => {
    $stateProvider.state(path, {
      url: path,
    });
  });
});
