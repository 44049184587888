import { IRootScopeService } from "angular";
import { IStateService } from "angular-ui-router";
import { CrossLocation, location } from "../util/location";

// eslint-disable-next-line sonarjs/cognitive-complexity
function RouterDecoratorConfig(
  $rootScope: IRootScopeService & { reqData?: { [key: string]: any } },
  $state: IStateService,
  Auth: any
) {
  "ngInject";

  if (
    process.env.API_ENDPOINT &&
    "ModalUnansweredFields" in window &&
    typeof ModalUnansweredFields === "object" &&
    typeof ModalUnansweredFields.App === "function"
  ) {
    // NOTE More at "field-validation-modal\src\App.tsx"

    ReactDOM.render(
      React.createElement(ModalUnansweredFields.App, {
        apiBaseUrl: process.env.API_ENDPOINT,
        graphqlUrl: process.env.MOORR_GRAPHQL_API_URL,
        onRequirementData(requirementData, clientSummary) {
          // eslint-disable-next-line no-param-reassign
          $rootScope.reqData = requirementData;

          $rootScope.$broadcast("reqData", requirementData);
          $rootScope.$broadcast("clientSummaryReqData", clientSummary);
        },
        location: {
          go(to: string, parameters?: { [key: string]: any }) {
            return $state.go(to, parameters);
          },

          // LINK field-validation-modal\src\routes\RouteChangeChecker.tsx
          setReactPush(newPush: CrossLocation["pushr"]) {
            location.pushr = newPush;
          },
        },
      }),
      document.querySelector("#react-root")
    );
  }

  // Redirect to login if route requires auth and the user is not logged in, or doesn't have required role

  $rootScope.$on("$locationChangeSuccess", (event, newUrlString) => {
    if (typeof newUrlString === "string") {
      const newUrl = new URL(newUrlString);
      location.pushr(newUrl.pathname + newUrl.search);
    }
  });

  $rootScope.$on("$stateChangeStart", (event: any, next: any) => {
    if (!next.authenticate) {
      return;
    }

    if (typeof next.authenticate === "string") {
      Auth.hasRole(next.authenticate, _.noop).then((has: any) => {
        if (has) {
          return;
        }

        event.preventDefault();

        return Auth.isLoggedIn(_.noop).then(() => {});
      });
    } else {
      Auth.isLoggedIn(_.noop).then((is: any) => {
        if (is) {
          return;
        }

        event.preventDefault();
      });
    }
  });
}

export default RouterDecoratorConfig;
