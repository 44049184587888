"use strict";

/**
 * A directive for adding google places autocomplete to a text box
 * google places autocomplete info: https://developers.google.com/maps/documentation/javascript/places
 *
 * Usage:
 *
 * <input type="text"  ng-autocomplete ng-model="autocomplete" options="options" details="details/>
 *
 * + ng-model - autocomplete textbox value
 *
 * + details - more detailed autocomplete result, includes address parts, latlng, etc. (Optional)
 *
 * + options - configuration for the autocomplete (Optional)
 *
 *       + types: type,        String, values can be 'geocode', 'establishment', '(regions)', or '(cities)'
 *       + bounds: bounds,     Google maps LatLngBounds Object, biases results to bounds, but may return results outside these bounds
 *       + country: country    String, ISO 3166-1 Alpha-2 compatible country code. examples; 'ca', 'us', 'gb'
 *       + watchEnter:         Boolean, true; on Enter select top autocomplete result. false(default); enter ends autocomplete
 *
 * example:
 *
 *    options = {
 *        types: '(cities)',
 *        country: 'ca'
 *    }
 **/
angular.module("ngAutocomplete", []).directive("ngAutocomplete", function () {
  return {
    require: "ngModel",
    scope: {
      ngModel: "=",
      options: "=?",
      details: "=?",
    },

    link: function (scope, element, attrs, controller) {
      //options for autocomplete

      var opts;
      var watchEnter = false;
      
      //convert options provided to opts
      var initOpts = function () {
        opts = {};
        scope.options = {
          country: "au",
        };
        if (scope.options) {
          if (scope.options.watchEnter !== true) {
            watchEnter = false;
          } else {
            watchEnter = true;
          }

          if (scope.options.types) {
            opts.types = [];
            opts.types.push(scope.options.types);
            scope.gPlace.setTypes(opts.types);
          } else {
            scope.gPlace.setTypes([]);
          }

          if (scope.options.bounds) {
            opts.bounds = scope.options.bounds;
            scope.gPlace.setBounds(opts.bounds);
          } else {
            scope.gPlace.setBounds(null);
          }

          if (scope.options.country) {
            opts.componentRestrictions = {
              country: scope.options.country,
            };
            scope.gPlace.setComponentRestrictions(opts.componentRestrictions);
          } else {
            scope.gPlace.setComponentRestrictions(null);
          }
        }
      };

      if (scope.gPlace == undefined) {
        scope.gPlace = new google.maps.places.Autocomplete(element[0], {});
        scope.gPlace.setFields(['address_components'])
      }
      google.maps.event.addListener(scope.gPlace, "place_changed", function () {
        var result = scope.gPlace.getPlace();
        if (result !== undefined) {
          if (result.address_components !== undefined) {
            scope.$apply(function () {
              // distribute address to specific fields
              scope.details = result;
              const incomeEmployment = scope.$parent.$parent.$parent.$parent.$parent.client1Employment;
              const incomeEmploymentSecondary = scope.$parent.$parent.$parent.$parent.$parent.client2Employment;
              const incomeEmploymentPrimaryPrevious = scope.$parent.$parent.$parent.$parent.$parent.client1PreviousEmployment;
              const incomeEmploymentSecondaryPrevious = scope.$parent.$parent.$parent.$parent.$parent.client2PreviousEmployment;

              const properties = scope.$parent.$parent.properties;
              let updatedAddr = "";
              let updatedAddrForSelfEmployed="";
              let incomeParent;

              const resetAddressAttribute = (address) => {
                address.addressClass = 'isValid isNotManual'
                address.isFocused = false
                address.isManual = false
              }

              switch (attrs.addresstype) {
                case "addProposedProperty":
                  updatedAddr =
                    scope.$parent.$parent.$parent.newProposedProperty;
                  scope.$parent.isAddressValid = true;
                  scope.$parent.$parent.$parent.$parent.isAddressValid = true;
                  scope.$parent.$parent.$parent.$parent.addressClass = 'isValid';
                  break;
                case "editProposedProperty":
                  updatedAddr =
                    scope.$parent.$parent.$parent.$parent
                      .currentProposedProperty;
                  scope.$parent.isAddressValid = true;
                  scope.$parent.$parent.$parent.$parent.isAddressValid = true;
                  scope.$parent.$parent.$parent.$parent.addressClass = 'isValid';
                  break;
                case "addProposedPropertyOld":
                  updatedAddr = scope.$parent.$parent.$parent.newProposedProperty;
                  scope.$parent.$parent.$parent.isAddressValid = true;
                  scope.$parent.$parent.$parent.addressClass = 'isValid';
                  break;
                case "editProposedPropertyOld":
                  updatedAddr = scope.$parent.$parent.currentProposedProperty;
                  scope.$parent.$parent.$parent.isAddressValid = true;
                  scope.$parent.$parent.$parent.addressClass = 'isValid';
                  break;
                case "properties":
                  updatedAddr = scope.$parent.$parent.property;

                  updatedAddr.address.addressClass = 'isValid isNotManual';
                  updatedAddr.address.isFocused = false;
                  updatedAddr.address.isManual = false;
                  break;
                case "income":
                  updatedAddr = incomeEmployment.find(i => i._id === attrs.propertyid)
                  if(updatedAddr) { // edit
                    resetAddressAttribute(updatedAddr.job.employer.address)
                  }
                  incomeParent = incomeEmployment;
                  break;
                case "incomeSelfEmployed": 
                  updatedAddr = incomeEmployment.find(i => i._id === attrs.propertyid)
                  if(updatedAddr) { // edit
                    resetAddressAttribute(updatedAddr.business.registeredAddress)
                  }
                  incomeParent = incomeEmployment;
                  break;
                case "incomeSecondary":
                  updatedAddr = incomeEmploymentSecondary.find(
                    (i) => i._id === attrs.propertyid
                  );
                  if(updatedAddr) { // edit
                    resetAddressAttribute(updatedAddr.job.employer.address)
                  }
                  incomeParent = incomeEmploymentSecondary;
                  break;
                case "client2Employment":
                  updatedAddr = incomeEmploymentSecondary.find(
                    (i) => i._id === attrs.propertyid
                  );
                  if(updatedAddr) { // edit
                    resetAddressAttribute(updatedAddr.business.registeredAddress)
                  }
                  incomeParent = incomeEmploymentSecondary;
                  break;
                case "client1PreviousEmployment":
                  updatedAddr = incomeEmploymentPrimaryPrevious.find(i => i._id === attrs.propertyid)
                  if(updatedAddr.employmentType==="Self Employed") {
                    resetAddressAttribute(updatedAddr.business.registeredAddress)
                  }
                  else {
                    resetAddressAttribute(updatedAddr.job.employer.address)
                  }
                  break;
                case "client2PreviousEmployment":
                  updatedAddr = incomeEmploymentSecondaryPrevious.find(i => i._id === attrs.propertyid)
                  if(updatedAddr.employmentType==="Self Employed") {
                    resetAddressAttribute(updatedAddr.business.registeredAddress)
                  }
                  else {
                    resetAddressAttribute(updatedAddr.job.employer.address)
                  }
                  break;
                
                case "incomeSecondaryPrevious":
                  updatedAddr = incomeEmploymentSecondaryPrevious.find(
                    (i) => i._id === attrs.propertyid
                  );
                  if(updatedAddr) { // edit
                    resetAddressAttribute()
                  }
                  incomeParent = incomeEmploymentSecondaryPrevious;
                  break;
                default:
                  updatedAddr = scope.$parent.$parent.properties.find(
                    (i) => i._id === attrs.propertyid
                  );
              }

              const FIRST_AND_ONLY_KEY = 0;
              const REGEX_MATCH_KEY = 1;
              
              function contains(target, pattern){
                let value = 0;
                pattern.forEach(function(word){
                  if(target.includes(word)) {
                    value++;
                  }
                });
                return (value === target.length)
              }

              function getUnit(address_components) {
                const ret = address_components.find(i => {
                  return i.types.includes('subpremise')
                })
                if(ret) {
                  return parseInt(ret.long_name)
                }
                else {
                  // if there is no result subpremise from google, we will get the unit number from the textfield value which is element.val()
                  // we can only get it if it has slash /
                  const match =  element.val().match(/(\d*)\//)
                  if(match)
                    return parseInt(match[REGEX_MATCH_KEY])
                }
              }
              function getNumber(address_components) {
                const ret = address_components.find(i => {
                  return i.types.includes('street_number')
                })
                if(ret) {
                  return ret.long_name
                }
                else {
                  // if there is no result subpremise from google, we will get the unit number from the textfield value which is element.val()
                  // we can only get it if it has slash /
                  const match =  element.val().match(/\/(\d*)/)
                  if(match)
                    return parseInt(match[REGEX_MATCH_KEY])
                }
              }
              function getStreet(address_components) {
                const ret = address_components.find(i => {
                  return i.types.includes('route')
                })
                if(ret) {
                  const z = ret.long_name.split(' ')
                  z.pop()
                  return z.join(' ')
                }
              }
              function getStreetType(address_components) {
                const ret = address_components.find(i => {
                  return i.types.includes('route')
                })
                if(ret) return ret.long_name.split(' ').splice(-1)[FIRST_AND_ONLY_KEY]
              }
              function getSuburb(address_components) {
                const ret = address_components.find(i => {
                  return contains(i.types, ['locality', 'political'])
                })
                if(ret) return ret.long_name
              }
              function getPostalCode(address_components) {
                const ret = address_components.find(i => {
                  return i.types.includes('postal_code')
                })
                if(ret) return ret.long_name
              }
              function getState(address_components) {
                const ret = address_components.find(i => {
                  return contains(i.types, ['administrative_area_level_1', 'political'])
                })
                if(ret) return ret.short_name
              }
              function getCountry(address_components) {
                const ret = address_components.find(i => {
                  return contains(i.types, ['country', 'political'])
                })
                if(ret) return ret.long_name
              }
              function setFormatted(address) {
                return [
                  address.floor,
                  [
                    address.unit, 
                    address.number
                  ].filter(Boolean).join('/'),
                  address.street,
                  address.streetType,
                  address.suburb,
                  address.postcode,
                  address.state,
                  address.country
                ].filter(Boolean).join(' ');
              }
              function setFormFields(address) {
                address.floor = "";
                address.unit= getUnit(result.address_components)
                address.number = getNumber(result.address_components)
                address.street = getStreet(result.address_components)
                address.streetType = getStreetType(result.address_components)
                address.suburb = getSuburb(result.address_components)
                address.postcode = getPostalCode(result.address_components)
                address.state = getState(result.address_components)
                address.country = getCountry(result.address_components)
                address.isAddressValid = true;
                address.addressClass = 'isValid isNotManual';
                
                address.formatted = setFormatted(address)
                address.frontFormatted = setFormatted(address)
                // controller.$setViewValue(setFormatted(address))
              }
              
              
              let toChange = null;
              let parsedData = null;
              let indexOfData = 0;

              // Existing
              if (updatedAddr !== undefined) {
                if (
                  attrs.addresstype === "income" ||
                  attrs.addresstype === "incomeSelfEmployed" ||
                  attrs.addresstype === "incomeSecondary" ||
                  attrs.addresstype === "incomeSecondaryPrevious" ||
                  attrs.addresstype === "client1PreviousEmployment" ||
                  attrs.addresstype === "client2PreviousEmployment" ||
                  attrs.addresstype === "client2Employment"
                ) {
                    // Self Employed
                    if(updatedAddr.employmentType==="Self Employed") {
                      if(!updatedAddr.business.registeredAddress) {
                        updatedAddr.business.registeredAddress = {}
                      }
                      setFormFields(updatedAddr.business.registeredAddress)
                    }
                    else { // Not Self Employed
                      setFormFields(updatedAddr.job.employer.address)
                    } // endif
                  
                } else {
                  setFormFields(updatedAddr.address)
                }
              } else { // NEW

                if (attrs.addresstype === "properties") {
                  parsedData = JSON.parse(attrs.alldata);

                  indexOfData = properties.findIndex((item) => {
                    const iAddress = item.address.formatted;
                    const parseAddress = parsedData.address.formatted;
                    const iOwner = item.owner.id;
                    const pOwner = parsedData.owner.id;
                    return (
                      iAddress === parseAddress &&
                      pOwner === iOwner &&
                      item.landSizeUnit === parsedData.landSizeUnit &&
                      item.ownership.ownershipType ===
                        parsedData.ownership.ownershipType &&
                      item.ownership.ownershipDesc ===
                        parsedData.ownership.ownershipDesc &&
                      item.material === parsedData.material
                    );
                  });
                  const streetType = getStreetType(
                    result.address_components
                  );
                  
                  toChange = properties[indexOfData];

                  setFormFields(toChange.address)

                  updatedAddr = toChange;
                } else if (
                  attrs.addresstype === "income" ||
                  attrs.addresstype === "incomeSelfEmployed" ||
                  attrs.addresstype === "incomeSecondary" ||
                  attrs.addresstype === "incomeSecondaryPrevious" ||
                  attrs.addresstype === "client1PreviousEmployment" ||
                  attrs.addresstype === "client2PreviousEmployment" ||
                  attrs.addresstype === "client2Employment"
                ) {
                  parsedData = JSON.parse(attrs.alldata);
                  indexOfData = incomeParent.findIndex((item) => {
                    if(item.employmentType === parsedData.employmentType) {
                      if(item.employmentType==="Self Employed") {
                        let iAddress = item.business.registeredAddress.formatted;
                        let pAddress = parsedData.business.registeredAddress.formatted;
                        return iAddress === pAddress;
                      }
                      else {
                        let iAddress = item.job.employer.address.formatted;
                        if(parsedData.employmentType!=="Self Employed") {
                          let pAddress = parsedData.job.employer.address.formatted;
                          return iAddress === pAddress;
                        }
                      }
                    }
                  });

                  toChange = incomeParent[indexOfData];

                  // Self Employed
                  if(toChange.employmentType==="Self Employed") {
                    if(!toChange.business.registeredAddress) {
                      toChange.business.registeredAddress = {}
                    }
                    
                    setFormFields(toChange.business.registeredAddress)

                  }
                  else { // Not Self Employed

                    setFormFields(toChange.job.employer.address)

                  } // endif
                  updatedAddr = toChange;
                }
              }

            });
          } else {
            if (watchEnter) {
              getPlace(result);
            }
          }
        }
      });

      //function to get retrieve the autocompletes first result using the AutocompleteService
      var getPlace = function (result) {
        var autocompleteService = new google.maps.places.AutocompleteService();
        if (result.name.length > 0) {
          autocompleteService.getPlacePredictions(
            {
              input: result.name,
              offset: result.name.length,
            },
            function listentoresult(list, status) {
              if (list == null || list.length == 0) {
                scope.$apply(function () {
                  scope.details = null;
                });
              } else {
                var placesService = new google.maps.places.PlacesService(
                  element[0]
                );
                placesService.getDetails(
                  { reference: list[0].reference },
                  function detailsresult(detailsResult, placesServiceStatus) {
                    if (placesServiceStatus == google.maps.GeocoderStatus.OK) {
                      scope.$apply(function () {
                        controller.$setViewValue(
                          detailsResult.formatted_address
                        );
                        element.val(detailsResult.formatted_address);
                        scope.details = detailsResult;

                        //on focusout the value reverts, need to set it again.
                        var watchFocusOut = element.on("focusout", function (
                          event
                        ) {
                          element.val(detailsResult.formatted_address);
                          element.unbind("focusout");
                        });
                      });
                    }
                  }
                );
              }
            }
          );
        }
      };

      controller.$render = function () {
        var location = controller.$viewValue;
        element.val(location);
      };

      //watch options provided to directive
      scope.watchOptions = function () {
        return scope.options;
      };
      scope.$watch(
        scope.watchOptions,
        function () {
          initOpts();
        },
        true
      );
    },
  };
});
