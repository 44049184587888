function AuthInterceptor($rootScope, $q, $cookies, $injector, Util) {
  'ngInject';

  var state;

  return {
    // Add authorization token to headers
    request(config) {
      config.headers = config.headers || {};
      if ($cookies.get('token') && Util.isSameOrigin(config.url)) {
        config.headers.Authorization = 'Bearer ' + $cookies.get('token');
        config.headers.OriginReferer = window.location.href;
      }
      return config;
    },

    // Intercept 401s and redirect you to login
    responseError(response) {
      return $q.reject(response);
    }
  };
}


function AuthConfig ($httpProvider) {
  'ngInject';

  $httpProvider.interceptors.push(AuthInterceptor);
}

export default AuthConfig;
