/**
 * Created by slasalle on 21/10/2016.
 */
"use strict";

angular
  .module("meanApp")
  .controller(
    "sidebar-controller",
    function (
      $scope,
      Auth,
      DataM,
      $state,
      $stateParams,
      $cookies,
      empowerDataService,
      appHostnames,
      $location,
      $rootScope
    ) {
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));
      $scope.isProd = Auth.isProdHost();
      $scope.surveyComplete = false;

      // TODO: I don't know how we want this to behave
      $scope.showBugReport = false;
      $scope.fileContents = [];
      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;
      $scope.showClientChanges = false;
      $scope.flexId1 = null;
      $scope.flexId2 = null;
      $scope.isSuperAdmin = false;
      $scope.isMBAdmin = false;
      $scope.client1Name = null;
      $scope.client2Name = null;
      $scope.personalInfo = null;
      $scope.loadingMessage = "";
      $scope.isUser = true;
      $scope.suitabilityList = [];
      $scope.showAdvisoryTeamModal = false;
      $scope.advisoryTeam = [];
      $scope.showSuitabilityTab = false;
      $scope.showSettings = false;
      $scope.showMBWLendingFlex = false;
      $scope.hasProperties = false;
      $scope.hasProposedProperties = false;
      $scope.hasFlexPermission = false;
      $scope.showFuturePlansBeta = false;
      $scope.properties = [];
      $scope.proposedProperties = [];
      // Update user email
      $scope.showUpdateUserEmailModal = false;
      $scope.updateEmailErrorMessage = "";
      $scope.updateEmailSuccessMessage = "";
      $scope.emailUpdateData = {};
      // Document Collection
      $scope.showDocumentCollectionTab = false;

      //get local storate
      $scope.getMeData = null;
      $scope.getMeData = JSON.parse(window.localStorage.getItem("getMeData"));
      if ($scope.getMeData) {
        $scope.hasProperties = $scope.getMeData.hasProperties;
        $scope.hasProposedProperties = $scope.getMeData.hasProposedProperties;
        $scope.isMBAdmin = $scope.getMeData.isMBAdmin;
        $scope.isSuperAdmin = $scope.getMeData.isSuperAdmin;
        $scope.isFutureInvestmentsHidden =
          $scope.getMeData.isFutureInvestmentsHidden;
        $scope.isUser = $scope.getMeData.isUser;
        $scope.showFuturePlansBeta = $scope.getMeData.showFuturePlansBeta;
        $scope.showSuitabilityTab = $scope.getMeData.showSuitabilityTab;
        $scope.showDocumentCollectionTab =
          $scope.getMeData.showDocumentCollectionTab;
        $scope.hasFlexPermission = $scope.getMeData.hasFlexPermission;
        $scope.showClientChanges = $scope.getMeData.showClientChanges;
        $scope.showSettings = $scope.getMeData.showSettings;
        $scope.surveyComplete = $scope.getMeData.surveyComplete;
      }
      $scope.isTPCHost = $scope.getMeData
        ? $scope.getMeData.isTPCHost
        : $rootScope.isTPCHost;
      // Flex
      $scope.showConfirmFlexSend = false;
      $scope.showFlexAlertModal = false;
      $scope.showFlexSuccessModal = false;
      $scope.flexResponseMessage = "";
      $scope.flexLoanWriter = {
        usernameToken: "",
        passwordText: "",
        description: "",
      };

      $scope.flexLogModal = false;
      $scope.flexLogSubmittedAt = "";
      $scope.xmlPayload = "";
      $scope.requireUpdatePages = [];

      $scope.isFutureInvestmentsHidden = true;

      // collapse
      $scope.isCollapsedExpenses = true;
      $scope.isCollapsedAssets = true;
      $scope.isCollapsedFutureInv = true;
      $scope.isCollapsedSuitability = true;
      $scope.isCollapsedProposedLending = true;

      $scope.isMBWorkbenchEnabled = false;

      $rootScope.$on("onClickClientFlexID", function (event, data) {
        $scope.showFlexId();
      });
      $rootScope.$on("onClickFlexSubmit", function (event, data) {
        $scope.showFlexInputModal();
      });
      $rootScope.$on("onClickFlexLog", function (event, data) {
        $scope.retrieveFlexLog();
      });

      var inExpensesPage =
        $state.current.url.includes("bills") ||
        $state.current.url.includes("spending");
      var inAssetsPage =
        $state.current.url.includes("property-assets") ||
        $state.current.url.includes("other-assets");
      var inFuturePlans =
        $state.current.url.includes("future-household") ||
        $state.current.url.includes("future-income-expenses") ||
        $state.current.url.includes("portfolio-planning");
      var inSuitability = $state.current.url.includes("suitability");
      if (inExpensesPage) $scope.isCollapsedExpenses = false;
      if (inAssetsPage) $scope.isCollapsedAssets = false;
      if (inFuturePlans) $scope.isCollapsedFutureInv = false;
      if (inSuitability) $scope.isCollapsedSuitability = false;
      // Money smarts
      $scope.showMoneySmartsTab = false;

      // Property Portfolio
      $scope.showPropertyPortfolioTab = false;

      $scope.test = function () {
        $scope.isCollapsedProposedLending = !$scope.isCollapsedProposedLending;
      };

      $scope.retrieveSideBarData = function () {
        DataM.getFlexMissingFields()
          .then(function (data) {
            if (data.error && data.error.sidebarFields) {
              if ($scope.hasFlexPermission) {
                $scope.requireUpdatePages = data.error.sidebarFields;
              }
            }
          })
          .catch(function (err) {
            console.log("Retrieve error", err);
          });
      };

      DataM.checkPermission({
        name: "viewClientChanges",
      })
        .then(function (data) {
          $scope.showClientChanges = data.hasPermission;
        })
        .catch(function (err) {
          console.log(err);
        });

      DataM.checkPermission({
        name: "updateEmail",
      })
        .then(function (data) {
          $scope.showUpdateEmailButton = data.hasPermission;
        })
        .catch(function (err) {
          console.log(err);
        });

      DataM.checkPermission({
        name: "submitFlex",
      })
        .then(function (data) {
          $scope.hasFlexPermission = data.hasPermission;
          $scope.retrieveSideBarData();
        })
        .catch(function (err) {
          console.log(err);
        });

      // check if enabled globally or individually
      function retrieveMoneySmartsSetting() {
        DataM.getSetting({ name: "MoneySmartsSetting" })
          .then(function (settingsData) {
            if (settingsData.selectedOption === "GlobalOn") {
              $scope.showMoneySmartsTab = true;
              $scope.showBugReport = true;
              return;
            } else {
              if (settingsData.selectedOption === "GlobalOff") {
                $scope.showMoneySmartsTab = false;
              }
            }
            // check individual setting
            if (
              settingsData.selectedOption === null ||
              settingsData.selectedOption === "Individual"
            ) {
              DataM.getMoneySmartsSetting({ uid: $stateParams.clientId })
                .then(function (settingData) {
                  $scope.showMoneySmartsTab = false;
                  if (settingData.moneySmartsEnabled === true) {
                    $scope.showMoneySmartsTab = true;
                    $scope.showBugReport = true;
                  }
                })
                .catch(function (err) {
                  console.log(err);
                  $scope.showSpinner = false;
                });
            }
          })
          .catch(function (err) {
            console.log("error", err);
          });
      }

      // check if enabled globally or individually
      function retrievePropertyPortfolioSetting() {
        DataM.getSetting({ name: "PropertyPortfolioSetting" })
          .then(function (settingsData) {
            if (settingsData.selectedOption === "GlobalOn") {
              $scope.showPropertyPortfolioTab = true;
              return;
            } else {
              if (settingsData.selectedOption === "GlobalOff") {
                $scope.showPropertyPortfolioTab = false;
              }
            }
            // check individual setting
            if (
              settingsData.selectedOption === null ||
              settingsData.selectedOption === "Individual"
            ) {
              DataM.getPropertyPortfolioSetting({ uid: $stateParams.clientId })
                .then(function (settingData) {
                  $scope.showPropertyPortfolioTab = false;
                  if (settingData.propertyPortfolioEnabled === true) {
                    $scope.showPropertyPortfolioTab = true;
                  }
                })
                .catch(function (err) {
                  console.log(err);
                  $scope.showSpinner = false;
                });
            }
          })
          .catch(function (err) {
            console.log("error", err);
          });
      }

      // check if enabled globally or individually
      function retrieveMaternityVersionSetting() {
        DataM.getSetting({ name: "MaternityVersionSetting" }).then(function (settingsData) {
          if (settingsData.selectedOption === "GlobalOn") {
            $scope.showOldMaternityTab = true;
            return;
          } else {
            if (settingsData.selectedOption === "GlobalOff") {
              $scope.showOldMaternityTab = false;
            }
          }
          // check individual setting
          if (settingsData.selectedOption === null || settingsData.selectedOption === "Individual") {
            DataM.getMaternityVersionSetting({ uid: $stateParams.clientId }).then(function (settingData) {
              $scope.showOldMaternityTab = false;
              if (settingData.oldMaternityEnabled === true) {
                $scope.showOldMaternityTab = true;
              }
            }).catch(function (err) {
              console.log(err);
              $scope.showSpinner = false;
            });

          }
        }).catch(function (err) {
          console.log('error', err)
        });
      }

      // check if enabled globally or individually
      function retrieveDocumentCollectionSetting() {
        DataM.getSetting({ name: "DocumentCollectionSetting" })
          .then(function (settingsData) {
            if (settingsData.selectedOption === "GlobalOn") {
              $scope.showDocumentCollectionTab = true;
              return;
            } else {
              if (settingsData.selectedOption === "GlobalOff") {
                $scope.showDocumentCollectionTab = false;
              }
            }
            // check individual setting
            if (
              settingsData.selectedOption === null ||
              settingsData.selectedOption === "Individual"
            ) {
              DataM.getDocumentCollectionSetting({ uid: $stateParams.clientId })
                .then(function (settingData) {
                  $scope.showDocumentCollectionTab = false;
                  if (settingData.documentCollectionEnabled === true) {
                    $scope.showDocumentCollectionTab = true;
                  }
                })
                .catch(function (err) {
                  console.log(err);
                  $scope.showSpinner = false;
                });
            }
          })
          .catch(function (err) {
            console.log("error", err);
          });
      }

      function retrieveSuitabilitySetting() {
        DataM.getSuitabilitySetting({ uid: $stateParams.clientId })
          .then(function (settingData) {
            $scope.showSuitabilityTab = false;
            if (settingData.suitabilityEnabled === true) {
              $scope.showSuitabilityTab = true;
              // retrieve locked suitability list
              retrieveSuitabilityList();
            }
          })
          .catch(function (err) {
            console.log(err);
            $scope.showSpinner = false;
          });
      }
      function retrieveSuitabilityList() {
        $scope.showSpinner = true;

        DataM.getSuitabilityReferenceIdList()
          .then(function (data) {
            $scope.showSpinner = false;
            $scope.suitabilityList = data;
          })
          .catch(function (err) {
            console.log("Retrieve Suitability error", err);
          });
      }
      $scope.$on("onSaved", function (event, args) {
        // do what you want to do
        console.log("did broadcast");
        if ($scope.hasFlexPermission) {
          $scope.retrieveSideBarData();
        }
      });

      // Listen if property portfolio settings was changed
      $scope.$on("onPropertyPortfolioSettingSaved", function (event, args) {
        retrievePropertyPortfolioSetting();
      });

      // Listen if old maternity settings was changed
      $scope.$on('onMaternityVersionSettingSaved', function (event, args) {
        retrieveMaternityVersionSetting();
      });

      // Listen if money smarts settings was changed
      $scope.$on("onMoneySmartsSettingSaved", function (event, args) {
        if ($scope.hasFlexPermission) {
          retrieveMoneySmartsSetting();
        }
      });

      // Listen if document collection settings was changed
      $scope.$on("onDocumentCollectionSettingSaved", function (event, args) {
        retrieveDocumentCollectionSetting();
      });

      // Listen if property portfolio settings was changed
      $scope.$on("onPropertyPortfolioSettingSaved", function (event, args) {
        retrievePropertyPortfolioSetting();
      });

      // Listen if money smarts settings was changed
      $scope.$on("onSuitabilitySettingSaved", function (event, args) {
        if ($scope.hasFlexPermission) {
          retrieveSuitabilitySetting();
        }
      });

      $scope.$on("onSuitabilityLocked", function (event, args) {
        // do what you want to do
        retrieveSuitabilityList();
      });

      Auth.isAdmin().then((isAdmin) => {
        if (!isAdmin) {
          DataM.getSummary()
            .then(function (data) {
              $scope.surveyComplete = data.clientSummary.surveyComplete;
              $scope.legacyId = data.clientSummary.legacyId;
              $scope.hasProperties = $scope.properties.length > 0;
              $scope.hasProposedProperties =
                $scope.proposedProperties.length > 0;
              if (
                data.clientSummary.personalInfo &&
                data.clientSummary.personalInfo.thirdParties
              ) {
                $scope.advisoryTeam =
                  data.clientSummary.personalInfo.thirdParties;
              }
              if (
                data.clientSummary.assets &&
                data.clientSummary.assets.properties
              ) {
                $scope.properties = data.clientSummary.assets.properties;
                setPropertyListNames();
              }
              if (!$scope.getMeData) {
                DataM.getMe()
                  .then(function (userData) {
                    if (userData.role !== "user" && userData.role !== "guest") {
                      $scope.isFutureInvestmentsHidden = false;
                      $scope.isUser = false;
                      $scope.showFuturePlansBeta = true;
                    } else {
                      $scope.isFutureInvestmentsHidden =
                        data.isPortfolioPlanningHidden;
                      $scope.showFuturePlansBeta =
                        userData.isFuturePlansEnabled;
                      $scope.isUser = true;
                    }
                    if (
                      userData.role === "super_admin" ||
                      userData.role === "mb_admin"
                    ) {
                      $scope.showSettings = true;
                    }
                    const getMeData = {
                      isMBAdmin: $scope.isMBAdmin,
                      isSuperAdmin: $scope.isSuperAdmin,
                      isFutureInvestmentsHidden:
                        $scope.isFutureInvestmentsHidden,
                      isUser: $scope.isUser,
                      showFuturePlansBeta: $scope.showFuturePlansBeta,
                      showSuitabilityTab: $scope.showSuitabilityTab,
                      showDocumentCollectionTab:
                        $scope.showDocumentCollectionTab,
                      hasFlexPermission: $scope.hasFlexPermission,
                      showClientChanges: $scope.showClientChanges,
                      showSettings: $scope.showSettings,
                      surveyComplete: $scope.surveyComplete,
                      isTPCHost: $scope.isTPCHost,
                      hasProperties: $scope.hasProperties,
                      hasProposedProperties: $scope.hasProposedProperties,
                    };
                    window.localStorage.setItem(
                      "getMeData",
                      JSON.stringify(getMeData)
                    );
                    //TODO: Refactor name of variable showSettting so it indicates better roles
                  })
                  .catch(function (err) {
                    console.log("Retrieve current user error");
                  });
              }

              // check income and survey
              if (data.clientSummary.surveyComplete === true) {
                $scope.isShortFactFindEnabled = false;
              } else {
                var hasDoneClientSurvey =
                  typeof data.clientSummary.clientSurvey !== "undefined";
                var hasDoneIncome =
                  typeof data.clientSummary.income !== "undefined";
                if (hasDoneClientSurvey && hasDoneIncome) {
                  $scope.isShortFactFindEnabled = false;
                } else if (Auth.getFFType() !== "Full") {
                  DataM.getSetting({ name: "ShowShortFactFind" })
                    .then(function (settingsData) {
                      if (
                        settingsData.isEnabled === true &&
                        $scope.showBugReport === false
                      ) {
                        $scope.isShortFactFindEnabled = true;
                      }
                    })
                    .catch(function (err) {
                      console.log("error in retrieving settings");
                      $scope.showSpinner = false;
                    });
                } else {
                  $scope.isShortFactFindEnabled = false;
                }
              }
            })
            .catch(function (err) {
              console.log("Retrieve error");
            });
        } else {
          $scope.surveyComplete = true;

          $scope.showFlexIdLink = true;
          $scope.showDeleteButton = true;

          DataM.getSummary()
            .then(function (data) {
              // check if user is admin
              if (
                data.clientSummary.assets &&
                data.clientSummary.assets.properties
              ) {
                $scope.properties = data.clientSummary.assets.properties;
                setPropertyListNames();
              }
              $scope.hasProperties = $scope.properties.length > 0;
              $scope.hasProposedProperties =
                $scope.proposedProperties.length > 0;
              if (!$scope.getMeData) {
                DataM.getMe()
                  .then(function (userData) {
                    if (userData.role !== "user" && userData.role !== "guest") {
                      $scope.isFutureInvestmentsHidden = false;
                      $scope.isUser = false;
                      $scope.showFuturePlansBeta = true;
                    } else {
                      $scope.isFutureInvestmentsHidden =
                        data.isPortfolioPlanningHidden;
                      $scope.isUser = true;
                      $scope.showFuturePlansBeta =
                        userData.isFuturePlansEnabled;
                    }
                    if (
                      userData.role === "super_admin" ||
                      userData.role === "mb_admin"
                    ) {
                      $scope.showSettings = true;
                    }
                    if (userData.role === "super_admin") {
                      $scope.isSuperAdmin = true;
                      $scope.isMBWorkbenchEnabled = true;
                    } else if (userData.role === "mb_admin") {
                      $scope.isMBAdmin = true;
                      $scope.isMBWorkbenchEnabled =
                        userData.isMBWorkbenchEnabled;
                    }
                    const getMeData = {
                      isMBAdmin: $scope.isMBAdmin,
                      isSuperAdmin: $scope.isSuperAdmin,
                      isFutureInvestmentsHidden:
                        $scope.isFutureInvestmentsHidden,
                      isUser: $scope.isUser,
                      showFuturePlansBeta: $scope.showFuturePlansBeta,
                      showSuitabilityTab: $scope.showSuitabilityTab,
                      showDocumentCollectionTab:
                        $scope.showDocumentCollectionTab,
                      hasFlexPermission: $scope.hasFlexPermission,
                      showClientChanges: $scope.showClientChanges,
                      showSettings: $scope.showSettings,
                      surveyComplete: $scope.surveyComplete,
                      isTPCHost: $scope.isTPCHost,
                      hasProperties: $scope.hasProperties,
                      hasProposedProperties: $scope.hasProposedProperties,
                    };
                    window.localStorage.setItem(
                      "getMeData",
                      JSON.stringify(getMeData)
                    );
                  })
                  .catch(function (err) {
                    console.log("Retrieve current user error");
                  });
              }

              $scope.personalInfo = data.clientSummary.personalInfo;

              if ($scope.personalInfo.client1) {
                $scope.flexId1 = "N/A";
                if ($scope.personalInfo.client1.flexId) {
                  $scope.flexId1 = $scope.personalInfo.client1.flexId;
                }
                $scope.client1Name =
                  $scope.personalInfo.client1.fName +
                  " " +
                  $scope.personalInfo.client1.lName;
              }

              if ($scope.personalInfo.client2) {
                $scope.flexId2 = "N/A";
                if ($scope.personalInfo.client2.flexId) {
                  $scope.flexId2 = $scope.personalInfo.client2.flexId;
                }
                $scope.client2Name =
                  $scope.personalInfo.client2.fName +
                  " " +
                  $scope.personalInfo.client2.lName;
              }
              if (
                data.clientSummary.personalInfo &&
                data.clientSummary.personalInfo.thirdParties
              ) {
                $scope.advisoryTeam =
                  data.clientSummary.personalInfo.thirdParties;
              }
            })
            .catch(function (err) {
              console.log("Retrieve error");
            });
        }
      });

      $scope.closeModal = function () {
        $scope.showFlexIdModal = false;
        $scope.showUpdateUserEmailModal = false;
        $scope.showAdvisoryTeamModal = false;
        $scope.emailUpdateData.newEmail = "";
        $scope.emailUpdateData.prevEmail = "";
        $scope.updateEmailErrorMessage = "";
        $scope.updateEmailSuccessMessage = "";
        $scope.showConfirmFlexSend = false;
        $scope.showFlexAlertModal = false;
        $scope.showFlexSuccessModal = false;
      };

      $rootScope.showFlexId = function () {
        console.log('jhere')
        $scope.showFlexIdModal = true;
      };

      $rootScope.trigerFlexSuccessModal = function () {
        $scope.showFlexSuccessModal = true;
      };

      $scope.showUpdateUserEmail = function () {
        $scope.showUpdateUserEmailModal = true;
      };

      $scope.showAdvisoryTeam = function () {
        $scope.showAdvisoryTeamModal = true;
      };

      $scope.checkIfLowerCase = function (email) {
        $scope.emailUpdateData.invalidEmailCase = !isLowerCase(email);

        if ($scope.emailUpdateData.invalidEmailCase == true) {
          $scope.updateEmailErrorMessage =
            "Email fields must be in lower case.";
        } else {
          $scope.updateEmailErrorMessage = "";
        }
      };

      function isLowerCase(string) {
        if (typeof string == "string") {
          return string == string.toString().toLowerCase();
        }
        return true;
      }
      $scope.submitUpdateUserEmail = function () {
        $scope.showSpinner = true;
        $scope.loadingMessage = "Loading";
        var formData = {
          clientId: $scope.clientId,
          newEmail: $scope.emailUpdateData.newEmail,
          prevEmail: $scope.emailUpdateData.prevEmail,
        };
        DataM.updateUserEmail(formData)
          .then(function (data, error) {
            // check status
            $scope.showSpinner = false;
            if (data && data.success === true) {
              $scope.updateEmailErrorMessage = "";
              $scope.updateEmailSuccessMessage =
                "Successfully updated user email.";
            }
          })
          .catch(function (err) {
            $scope.showSpinner = false;
            $scope.updateEmailSuccessMessage = "";
            if (err.data) {
              if (err.data.message) {
                $scope.updateEmailErrorMessage = err.data.message;
              }
            } else {
              $scope.updateEmailErrorMessage =
                "Unable to update User email. Please contact Admin.";
            }
          });
      };

      $scope.retrieveFlexLog = function () {
        $scope.showSpinner = true;
        DataM.getFlexLog()
          .then(function (data) {
            if (data.success === false) {
              if (data.response) {
                if (data.response.includes("missingFields")) {
                  var requiredFields = JSON.parse(data.response);
                  $scope.flexResponseMessage =
                    getFlexResponseMessage(requiredFields);
                  $scope.requireUpdatePages = requiredFields.sidebarFields;
                } else {
                  $scope.flexResponseMessage = data.response;
                }
              } else {
                $scope.flexResponseMessage =
                  "Flex server error occured during push";
              }
              $scope.showFlexAlertModal = true;
            } else {
              $scope.showFlexSuccessModal = true;

              $scope.flexOpportunityID = data.response.FLEXId;
            }
            $scope.flexLogModal = true;
            $scope.flexLogSubmittedAt = data.dateSubmitted;
            $scope.showSpinner = false;
          })
          .catch(function (err) {
            console.log("Retrieve error", err);
            $scope.showSpinner = false;
            $scope.flexResponseMessage = "Unable to retrieve log";
            $scope.showFlexAlertModal = true;
          });
      };

      //retrieveMoneySmartsSetting(); move this to the end so it will override the default when the user had access to Money S.M.A.R.T.S.

      $scope.submitFlex = function () {
        var clientId = $scope.clientId;
        $scope.xmlPayload = "";
        $scope.payloadDisplayed = false;
        $scope.flexLogModal = false;
        if (clientId !== null) {
          $scope.showSpinner = true;
          DataM.pushFlex({
            userId: clientId,
            UsernameToken: $scope.flexLoanWriter.usernameToken,
            PasswordText: $scope.flexLoanWriter.passwordText,
            Description: $scope.flexLoanWriter.description,
          })
            .then(function (data) {
              $scope.toJSON = "";
              $scope.showSpinner = false;
              if (data.error) {
                $scope.showFlexAlertModal = true;
                $scope.flexResponseMessage = getFlexResponseMessage(data.error);
              } else if (!data.error && data.outboundMsg.Opportunity) {
                if (data.outboundMsg.Opportunity.FLEXId) {
                  $scope.showFlexSuccessModal = true;

                  $scope.flexOpportunityID =
                    data.outboundMsg.Opportunity.FLEXId;
                  $scope.xmlPayload = data.inboundMsg;
                  // reset the input form
                  $scope.flexLoanWriter = {
                    usernameToken: "",
                    passwordText: "",
                    description: "",
                  };
                }
              } else {
                $scope.xmlPayload = data.inboundMsg;

                $scope.showFlexAlertModal = true;
                $scope.flexResponseMessage = getFlexResponseMessage(
                  data.outboundMsg
                );
              }
            })
            .catch(function (err) {
              console.log("FLEX Error", err);

              $scope.showSpinner = false;
              $scope.showFlexAlertModal = true;
              if (err.status !== 403) {
                $scope.flexResponseMessage = "Flex Data Mapping error occured";
              } else {
                $scope.flexResponseMessage = "User not allowed to push in Flex";
              }
            });
        }
      };

      $rootScope.showFlexInputModal = function () {
        $scope.showConfirmFlexSend = true;
      };
      $scope.showUploadDocumentModal = function () {
        $scope.showUploadModal = true;
      };

      function getFlexResponseMessage(flexResponse) {
        var responseMessage = "";
        try {
          if (flexResponse.missingFields) {
            responseMessage = "Fields Required: \n";
            responseMessage += flexResponse.missingFields.join("\n");
          } else if (flexResponse.headers) {
            if (flexResponse.headers["siebel-error-message-1"]) {
              responseMessage = flexResponse.headers["siebel-error-message-1"];
            } else {
              if (flexResponse.body) {
                if (
                  flexResponse.body["SOAP-ENV:Envelope"] &&
                  flexResponse.body["SOAP-ENV:Envelope"]["SOAP-ENV:Body"] &&
                  flexResponse.body["SOAP-ENV:Envelope"]["SOAP-ENV:Body"][0]
                ) {
                  if (
                    flexResponse.body["SOAP-ENV:Envelope"]["SOAP-ENV:Body"][0][
                      "SOAP-ENV:Fault"
                    ] &&
                    flexResponse.body["SOAP-ENV:Envelope"]["SOAP-ENV:Body"][0][
                      "SOAP-ENV:Fault"
                    ][0]["faultstring"]
                  ) {
                    responseMessage =
                      flexResponse.body["SOAP-ENV:Envelope"][
                        "SOAP-ENV:Body"
                      ][0]["SOAP-ENV:Fault"][0]["faultstring"];
                  } else {
                    responseMessage =
                      "Server error occured during flex push. Please try again later.";
                  }
                } else {
                  responseMessage =
                    "Server error occured during flex push. Please try again later.";
                }
              }
            }
          } else {
            responseMessage =
              "Server error occured during flex push. Please try again later.";
          }
          return responseMessage;
        } catch (err) {
          console.log("Flex response error");
          var responseMessage =
            "Server error occured during flex push. Please try again later.";
          return responseMessage;
        }
      }

      $scope.isUpdateReqPage = function (pageName) {
        var index = $scope.requireUpdatePages.indexOf(pageName);

        return index > -1;
      };

      $scope.generateClientOnAPageURL = function () {
        // Ensure email is set
        if (!$scope.personalInfo) return null;

        const clientOnAPageBase = "https://coap.iif.wealthpathlabs.com/";
        const email = $scope.personalInfo.client1.email;
        const token = $cookies.get("token");

        return `${clientOnAPageBase}?email=${email}&token=${token}`;
      };

      function setPropertyListNames() {
        if ($scope.hasProperties || $scope.hasProposedProperties) {
          for (var i = 0; i < $scope.properties.length; i++) {
            var property = $scope.properties[i];
            if (property) {
              property.addressFull = empowerDataService.generateAddressShort(
                property.address
              );
            }
          }
          for (var i = 0; i < $scope.proposedProperties.length; i++) {
            var property = $scope.proposedProperties[i];

            if (property) {
              property.addressFull = empowerDataService.generateAddressShort(
                property.address
              );
            }
          }
        }
      }
      function getProposedPropertyList() {
        var clientID = $scope.clientId;

        var formData = {
          uid: clientID,
        };
        DataM.getProposedPropertyList(formData).then(function (data) {
          if (data.properties) {
            $scope.proposedProperties = data.properties;
            setPropertyListNames();
          }
        });
      }

      getProposedPropertyList();
      retrieveMoneySmartsSetting();
      retrieveDocumentCollectionSetting();
      retrieveSuitabilitySetting();
      retrievePropertyPortfolioSetting();
      retrieveMaternityVersionSetting();

      $scope.showTour = function () {
        $state.transitionTo(
          $state.current,
          { clientId: $scope.clientId, showTour: true },
          { reload: true, inherit: false, notify: true }
        );
      };

      $scope.openPWPWorkbench = function () {
        let url = window.location.origin + "/pwpworkbench/" + $scope.clientId;
        window.open(url, "newWin", "width=" + 1200 + ",height=" + 900);
      };
    }
  );
